@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "components/variables";
@import "components/mixins";
/* ============================================================================
Carousel component
============================================================================ */

$ex: ex-static-product-carousel-tile;

.#{$ex} {
    height: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    &__image {
        max-height: 100%;
        width: 100%;
    }

    &__bottom {
        display: flex;
        margin-top: 12px;
        text-align: center;

        @include bp(min-width, $bp-large) {
            margin-top: 24px;
        }
    }

    &__snippet {
        margin-bottom: 12px;
    }
}

.spc-cta-only {
    .#{$ex} {
        &__snippet {
            display: none;
        }
    }
}

.spc-snippet-top {
    .#{$ex} {
        &__bottom {
            flex-direction: column;
        }
    }
}

.spc-snippet-bottom {
    .#{$ex} {
        &__snippet {
            margin-bottom: 0;
            margin-top: 12px;
        }

        &__bottom {
            flex-direction: column-reverse;
        }
    }
}

// Override global styles
.page-designer-reference {
    .#{$ex} {
        &__link-image {
            &:active,
            &:focus,
            &:hover {
                + .#{$ex}__bottom .#{$ex}__cta {
                    text-decoration: underline;
                }
            }
        }

        &__snippet {
            > * {
                margin: 0;
            }
        }

        &__cta {
            color: inherit;

            &:active,
            &:focus,
            &:hover {
                color: inherit;
            }
        }
    }
}
